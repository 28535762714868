/* General styling */
body {
    margin: 0;
    font-family: Arial, sans-serif;
  }
  
  .bg-white {
    background-color: #ffffff;
  }
  
  /* Container for main layout */
  .main-container {
    max-width: 1200px; /* Adjust the max width to fit your design */
    margin: 0 auto;
    padding: 0 1rem;
  }
  
  /* Grid layout for products */
  .product-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1rem;
  }
  
  /* Product card styling */
  .product-card {
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.05);
    overflow: hidden;
    transition: transform 0.2s ease-in-out;
  }
  
  .product-card:hover {
    transform: scale(1.02);
  }
  
  .product-image {
    width: 100%;
    height: 200px; /* Fixed height for image to maintain card consistency */
    object-fit: cover;
  }
  
  .product-info {
    padding: 1rem;
  }
  
  /* Filter panel styling */
  .filter-panel {
    border: 1px solid #e5e7eb;
    padding: 1rem;
    border-radius: 0.375rem;
    margin-bottom: 1rem; /* Space below the filter panel */
    background-color: #f9fafb;
  }
  
  .filter-button {
    display: flex;
    justify-content: space-between;
    padding: 0.5rem;
    background-color: #ffffff;
    border: 1px solid #e5e7eb;
    border-radius: 0.375rem;
    cursor: pointer;
  }
  
  .filter-panel input[type="radio"] {
    margin-right: 0.5rem;
  }
  
  .filter-panel label {
    margin-left: 0.5rem;
  }
  
  /* Responsive adjustments */
  @media (max-width: 1024px) {
    .filter-panel {
      width: 100%; /* Ensure filter panel takes full width on smaller screens */
    }
  
    .product-grid {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
    }
  }
  
  /* For mobile screens */
  @media (max-width: 640px) {
    .filter-button {
      font-size: 0.875rem; /* Smaller font size on mobile */
    }
  
    .product-info {
      padding: 0.5rem; /* Reduce padding on mobile for better spacing */
    }
  
    .product-image {
      height: 150px; /* Smaller height for images on mobile */
    }
  
    .product-card {
      border-radius: 0.25rem; /* Smaller border radius on mobile */
    }
  }
  
  /* Loading spinner */
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
  }
  
  .loading-spinner::after {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    border: 4px solid #f3f3f3;
    border-top: 4px solid #3498db;
    border-radius: 50%;
    animation: spin 1s linear infinite;
  }
  
  @keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
  }
  
  /* General Button Styling */
/* General Button Styling */
.disclosure-button {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    background-color: #ffffff; /* White background */
    padding: 0.75rem 1rem; /* Adjust padding for touch-friendly areas */
    color: #9ca3af; /* Gray text color */
    border: 1px solid #e5e7eb; /* Light gray border */
    border-radius: 0.375rem; /* Rounded corners */
    transition: background-color 0.2s ease, color 0.2s ease; /* Smooth transition */
  }
  
  /* Hover State */
  .disclosure-button:hover {
    color: #6b7280; /* Darker gray text on hover */
    background-color: #f3f4f6; /* Light gray background on hover */
  }
  
  /* Font and Icon Styling */
  .disclosure-button span {
    display: flex;
    align-items: center;
  }
  
  /* Icon Container */
  .disclosure-button .icon-container {
    display: flex;
    align-items: center;
    margin-left: 1.5rem; /* Space between text and icon */
  }
  
  /* Responsive Adjustments */
  @media (max-width: 1024px) { /* For tablets and smaller desktops */
    .disclosure-button {
      padding: 0.5rem 0.75rem; /* Adjust padding for tablets */
      font-size: 0.875rem; /* Slightly smaller font size */
    }
  
    .disclosure-button .icon-container {
      margin-left: 1rem; /* Adjust space between text and icon */
    }
  }
  
  @media (max-width: 640px) { /* For mobile devices */
    .disclosure-button {
      padding: 0.5rem; /* Reduce padding on mobile */
      font-size: 0.75rem; /* Smaller font size for mobile */
    }
  
    .disclosure-button .icon-container {
      margin-left: 0.75rem; /* Adjust space between text and icon */
    }
  }
  