/* Sidebar Styles */
.navbar-aside {
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 290px;
    background-color: #f8f9fa;
    transition: transform 0.3s ease;
    transform: translateX(0);
    z-index: 1000;
    overflow-y: auto;
  }
  
  .brand-wrap {
    display: flex;
    align-items: center;
    padding: 15px;
    border-bottom: 1px solid #ddd;
  }
  
  .font-bold {
    font-weight: bold;
    margin-left: 10px;
  }
  
  .btn-aside-minimize {
    display: none;
  }
  
  .menu-aside {
    list-style: none;
    padding: 0;
    margin: 0;
  }
  
  .menu-item {
    margin: 0;
  }
  
  .menu-link {
    display: flex;
    align-items: center;
    padding: 15px;
    color: #333;
    text-decoration: none;
  }
  
  .menu-link:hover,
  .menu-link.active {
    background-color: #e9ecef;
    color: #007bff;
  }
  
  .menu-link .icon {
    margin-right: 10px;
  }
  
  @media (max-width: 768px) {
    .navbar-aside {
      width: 100%;
      transform: translateX(-100%);
    }
  
    .navbar-aside.show {
      transform: translateX(0);
    }
  
    .btn-aside-minimize {
      display: block;
      position: absolute;
      top: 15px;
      right: 15px;
      z-index: 1100;
    }
  
    .aside-mini .navbar-aside {
      width: 80%;
    }
  }
  