nav {
    display: flex;
    flex-direction: column;
    height: auto;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
}

nav svg {
    width: 25px;
    height: 25px;
    cursor: pointer;
}

.nav svg:hover {
    color: #32d345;
}

nav .heading {
    font-size: 18px;
    font-weight: 600;
    display: inherit;
    width: 100%;
    height: 22px;
    overflow: hidden;
    text-overflow: ellipsis;
}

nav .s1 {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

nav .searchbar-container {
    display: flex;
    align-items: center;
    box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.2);
    border-radius: 14px;
    padding: 0 8px;
    margin-left: 4rem;
    margin-right: 10rem;
    width: 20rem;
    background-color: white;
    position: relative;
}

nav .searchbar-container input {
    border: none;
    outline: none;
    width: 100%;
    font-size: 14px;
    padding: 10px 12px 10px 40px;
    border-radius: 14px;
    background-color: white;
}

nav .searchbar-container input:focus {
    outline: none;
    box-shadow: none;
}

nav .searchbar-container .search-icon {
    position: absolute;
    left: 12px;
    color: #090909;
    font-size: 16px;
    pointer-events: none;
}

nav .searchbar svg {
    color: var(--col1);
}

nav .searchbar svg:hover {
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    padding: 5px;
}

nav .searchbar button {
    border: none;
    background-color: transparent;
    cursor: pointer;
}

nav .s1 .right {
    display: flex;
    justify-content: space-between;
    gap: 10px;
    align-items: center;
}

nav .cart {
    position: relative;
    margin: 0px 20px;
    color: rgb(255, 255, 255);
}

nav .cart .qty {
    background-color: var(--col1);
    color: white;
    border-radius: 50%;
    position: absolute;
    width: 25px;
    height: 25px;
    top: -15px;
    right: -15px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 12px;
    text-align: center;
}

nav .s2 {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    gap: 3%;
}

nav .s2 a {
    text-decoration: none;
    color: black;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
}

nav .s2 a:hover {
    color: var(--col1);
}

nav .s3 {
    display: none;
}

/* Media Queries for Responsive Design */
@media (max-width: 768px) {
    nav {
        flex-direction: column;
        height: auto;
    }

    nav .s1 {
        flex-direction: column;
        align-items: flex-start;
    }

    nav .searchbar-container {
        margin-left: 0;
        margin-right: 0;
        width: 100%;
    }

    nav .s2 {
        flex-direction: column;
        gap: 10px;
    }

    nav .s1 .right {
        flex-direction: column;
        gap: 10px;
    }
}

@media (max-width: 480px) {
    nav .heading {
        font-size: 16px;
    }

    nav .searchbar-container {
        padding: 0 4px;
        width: 100%;
    }

    nav .searchbar-container input {
        font-size: 12px;
        padding: 8px 10px 8px 30px;
    }

    nav .searchbar-container .search-icon {
        font-size: 14px;
    }

    nav .cart .qty {
        width: 20px;
        height: 20px;
        font-size: 10px;
    }

    nav .s2 a {
        font-size: 12px;
    }
}
/* Ensure mobile view styles */
@media (max-width: 767px) {
    .mobile-slider {
      position: relative;
      z-index: 50; /* High z-index to appear on top of other content */
    }
    .mobile-slider .subcategories {
      position: absolute; /* Ensure it appears on top */
      top: 100%; /* Position it below the button */
      left: 0;
      width: 100%;
      z-index: 50; /* High z-index to appear on top */
      background-color: white;
    }
  }
  