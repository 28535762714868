
.header-container {
    padding-top: 10px;
    background-color: #f3f4f6;
    margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
  }
  
  .faq-header {
    text-align: center;
    margin-bottom: 20px;
  }
  
  .steps-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .step-card {
    width: 23%;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 15px;
    margin: 10px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .step-circle {
    background-color:#f0c014;
    color: white;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
    font-size: 18px;
    font-weight: bold;
  }
  
  .step-title {
    margin-top: 10px;
    font-weight: bold;
  }
  
  .popular-categories {
    padding: 20px;
    background-color:   #FFFAE3;
  }
  
  .categories-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 2px));
    margin-left: 60px;
    gap: 10px;
  }
  
  .category-card {
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .benefits-container {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px;
  }
  
  .benefit-card {
    width: 30%;
    background-color: white;
    border: 1px solid #e5e7eb;
    border-radius: 8px;
    padding: 15px;
    text-align: center;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    margin-bottom: 20px;
}

.benefit-title {
    font-weight: bold;
    margin-top: 10px;
}
.faq-container {
  margin-top: 10px;
  margin-left: auto;
  margin-right: auto;
    width: 90%;
    padding: 20px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .faq-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .faq-list {
    margin: 0;
    padding: 0;
    list-style: none;
  }
  
  .faq-item {
    margin-bottom: 15px;
  }
  
  .faq-question {
    font-size: 18px;
    background-color: #f0c014;
    color: white;
    padding: 10px;
    border-radius: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .faq-answer {
    padding: 10px;
    margin-top: 5px;
    background-color: #f1f1f1;
    border-left: 3px solid #f0c014;
    border-radius: 0 5px 5px 5px;
    color: #333;
  } 
  .seller-blogs {
    width: 90%;
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color:#f1f1f1;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .blog-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 24px;
    color: #333;
  }
  
  .blog-list {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .blog-item {
    padding: 15px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .blog-item h3 {
    font-size: 20px;
    margin-bottom: 10px;
    color:#f0c014;
  }
  
  .blog-item p {
    font-size: 16px;
    margin-bottom: 10px;
    color: #555;
  }
  
  .payment-cycle-container {
    max-width: 90%;
    margin-top: 10px;
    margin: 0 auto;
    padding: 20px;
    margin-bottom: 20px;
    background-color: #FFFAE3;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    border: 2px solid #f0c014;
  }
  
  .payment-cycle-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    text-align: center;
    margin-bottom: 2rem;
    /* text-shadow: 1px 1px 2px #333 ; */
  }
  
  .payment-cycle-section {
    margin-bottom: 1.5rem;
    padding: 15px;
    background-color: #FFF8DC;
    border-left: 6px solid #f0c014;
    border-radius: 8px;
  }
  
  .payment-cycle-subtitle {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 1rem;
    /* text-shadow: 1px 1px 2px #333; */
  }
  
  .payment-cycle-subheading {
    font-size: 1.5rem;
    font-weight: 600;
    color:  #333;
    margin-bottom: 0.75rem;
    /* text-shadow: 1px 1px 2px  #333; */
  }
  
  .payment-cycle-text {
    font-size: 1.1rem;
    color:  #333;
    margin-bottom: 1rem;
    line-height: 1.6;
  }
  .payment-cycle-img {
    max-width: 250px; /* Adjust the size of the image */
    height: auto;
    margin-right: 100px; /* Space between text and image */
  }
  .payment-cycle-img1 {
    max-width: 50%; /* Adjust the size of the image */
    height: auto;
    margin-right: 100px; /* Space between text and image */
  }
  .payment-cycle-heading {
    display: flex;
    align-items: center;
    justify-content: space-between; /* This aligns heading to the left and image to the right */
  }
  .payment-cycle-img {
    max-width: 250px; /* Adjust the size of the image */
    height: auto;
    margin-left: 20px; /* Space between text and image */
  }
  
  
  .payment-cycle-list {
    list-style-type: disc;
    margin-left: 20px;
    margin-bottom: 1rem;
    color:  #333;
  }
  
  .payment-cycle-code {
    display: block;
    background-color: #FFEBCD;
    color:  #333;
    padding: 10px;
    border-radius: 5px;
    border: 1px solid  #f0c014;
    margin: 10px 0;
    font-family: 'Courier New', Courier, monospace;
  }
  .create-account-container {
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
    padding: 20px;
    background-color: #FFFAE3;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
  }
  
  .create-account-title {
    font-size: 2.5rem;
    color: #f0c014;
    margin-bottom: 20px;
    text-align: center;
  }
  
  .create-account-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .documents-section {
    background-color: #fef9e7;
    padding: 10px;
    border-left: 4px solid #f0c014;
    margin-bottom: 20px;
  }
  
  .gst-section {
    margin-bottom: 20px;
  }
  
  .gst-title {
    font-size: 1.5rem;
    color: #f0c014;
    margin-bottom: 10px;
  }
  
  .gst-description {
    margin-bottom: 10px;
  }
  
  .gst-steps {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .gst-steps li {
    margin-bottom: 10px;
  }
  
  .categories-section {
    margin-bottom: 20px;
  }
  
  .categories-title {
    font-size: 1.5rem;
    color: #f0c014;
    margin-bottom: 10px;
  }
  
  .categories-description {
    margin-bottom: 20px;
  }
  
  .categories-list {
    columns: 2;
    column-gap: 20px;
    padding-left: 0;
    list-style-type: none;
  }
  
  .categories-list li {
    margin-bottom: 10px;
  }
  
  a {
    color: #f0c014;
    text-decoration: none;
  }
  
  a:hover {
    text-decoration: underline;
  }
  
  @media (max-width: 768px) {
    .create-account-title {
      font-size: 2rem;
    }
    
    .categories-list {
      columns: 1;
    }
  }
  .list-products-container {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .content-section {
    flex: 1;
    margin-right: 20px;
  }
  
  .list-products-title {
    font-size: 2.5rem;
    color: #f0c014;
    margin-bottom: 20px;
  }
  
  .list-products-description {
    font-size: 1rem;
    line-height: 1.6;
    margin-bottom: 20px;
  }
  
  .list-products-highlight {
    font-size: 1rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 20px;
  }
  
  .list-products-subheading {
    font-size: 1.2rem;
    color: #f0c014;
    margin-bottom: 10px;
  }
  
  .listing-methods {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .listing-methods li {
    margin-bottom: 15px;
    font-size: 1rem;
  }
  
 
.idea-icon {
  width: 24px;
  height: 24px;
  margin-right: 10px;
}
  
  @media (max-width: 768px) {
    .list-products-container {
      flex-direction: column;
      align-items: center;
    }
  
    .content-section {
      margin-right: 0;
      margin-bottom: 20px;
    }
  
  }
  .payment-cycle-img2 {
    max-width: 250px; /* Adjust the size of the image */
    height: auto;
    margin-right: 100px; /* Space between text and image */
  }
  .storage-shipping-container {
    background-color: #fff9e6;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    margin: 20px auto;
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .storage-shipping-title {
    font-size: 2.5rem;
    color: #f0c014;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 1.8rem;
    color: #f0c014;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0c014;
    padding-bottom: 10px;
  }
  
  .section-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .guidelines-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .guidelines-list li {
    margin-bottom: 10px;
  }
  
  @media (max-width: 768px) {
    .storage-shipping-container {
      padding: 20px;
    }
  
    .storage-shipping-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  
    .section-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  
    .section-description {
      font-size: 1rem;
    }
  
    .guidelines-list {
      font-size: 0.9rem;
    }
  }
  .return-payment-container {
    background-color: #fff9e6;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    color: #333;
    margin: 20px auto;
    max-width: 90%;  
    margin-top: 10px;
    margin-left: auto;
    margin-right: auto;
  }
  
  .return-payment-title {
    font-size: 2.5rem;
    color: #f0c014;
    text-align: center;
    margin-bottom: 40px;
  }
  
  .section-title {
    font-size: 1.8rem;
    color: #f0c014;
    margin-bottom: 20px;
    border-bottom: 2px solid #f0c014;
    padding-bottom: 10px;
  }
  
  .section-description {
    font-size: 1.2rem;
    margin-bottom: 20px;
    line-height: 1.6;
  }
  
  .process-list,
  .timelines-list {
    list-style-type: disc;
    padding-left: 20px;
    font-size: 1rem;
    line-height: 1.6;
  }
  
  .process-list li,
  .timelines-list li {
    margin-bottom: 10px;
  }
  
  .timelines-list strong {
    color: #333;
  }
  
  @media (max-width: 768px) {
    .return-payment-container {
      padding: 20px;
    }
  
    .return-payment-title {
      font-size: 2rem;
      margin-bottom: 20px;
    }
  
    .section-title {
      font-size: 1.5rem;
      margin-bottom: 15px;
    }
  
    .section-description {
      font-size: 1rem;
    }
  
    .process-list,
    .timelines-list {
      font-size: 0.9rem;
    }
  }
  
  /* Container styling */
.list-products-container {
  padding: 1rem; /* Add padding around the container */
}

.content-section {
  max-width: 1200px;
  margin: 0 auto; /* Center the content section */
  padding: 1rem;
}

/* Heading styles */
.list-products-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
  text-align: center; /* Center-align heading */
}

.payment-cycle-heading {
  display: flex;
  flex-direction: column; /* Stack elements vertically */
  align-items: center; /* Center-align items horizontally */
  text-align: center; /* Center-align text */
}

.list-products-description {
  margin-bottom: 1rem;
}

.payment-cycle-img2 {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}

/* Highlight text styles */
.list-products-highlight {
  font-weight: bold;
  color: #4a5568; /* Gray color */
  margin: 1rem 0;
}

/* Subheading styles */
.list-products-subheading {
  font-size: 1.25rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Listing methods */
.listing-methods {
  margin: 0;
  padding-left: 1.5rem;
  list-style-type: disc; /* Bullet points for list items */
}

.listing-methods li {
  margin-bottom: 1rem;
}

@media (min-width: 768px) {
  .payment-cycle-heading {
    flex-direction: row; /* Align items in a row on larger screens */
    justify-content: space-between; /* Space items evenly */
  }

  .payment-cycle-img2 {
    max-width: 50%; /* Limit image width on larger screens */
    margin: 0;
  }

  .list-products-title {
    font-size: 2.5rem; /* Increase font size for larger screens */
  }

  .list-products-description,
  .list-products-highlight,
  .list-products-subheading {
    font-size: 1.125rem; /* Increase font size on larger screens */
  }
}

@media (min-width: 1024px) {
  .content-section {
    padding: 2rem;
  }
}
/* Container styling */
.categories-section {
  padding: 1rem;
  text-align: center; /* Center-align text */
}

/* Title styling */
.categories-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Description styling */
.categories-description {
  font-size: 1rem;
  color: #4a5568; /* Gray color */
  margin-bottom: 2rem;
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align description */
}

/* Popular categories heading */
.popular-categories h2 {
  font-size: 1.75rem;
  font-weight: bold;
  margin-bottom: 1.5rem;
}

/* Grid layout for categories */
.categories-grid {
  display: grid;
  gap: 1rem; /* Space between items */
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr)); /* Responsive grid */
}

/* Category card styling */
.category-card {
  background-color: #f7fafc; /* Light background for cards */
  border: 1px solid #e2e8f0; /* Border around cards */
  border-radius: 0.375rem; /* Rounded corners */
  padding: 1rem;
  text-align: center;
  font-size: 1rem;
  font-weight: bold;
  color: #2d3748; /* Dark text color */
}

@media (min-width: 768px) {
  .categories-title {
    font-size: 2rem; /* Larger font size for bigger screens */
  }

  .categories-description {
    font-size: 1.125rem; /* Larger font size on larger screens */
  }
  
  .popular-categories h2 {
    font-size: 2rem; /* Larger font size for the heading */
  }
}

@media (min-width: 1024px) {
  .categories-section {
    padding: 2rem; /* Extra padding on larger screens */
  }
  
  .categories-grid {
    grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); /* Adjust grid columns */
  }
}
/* Container styling */
.storage-shipping-container {
  padding: 1rem;
  text-align: center; /* Center-align text */
}

/* Title styling */
.storage-shipping-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Section title styling */
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Description styling */
.section-description {
  font-size: 1rem;
  color: #4a5568; /* Gray color */
  margin-bottom: 1.5rem;
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align description */
}

/* Image styling */
.payment-cycle-img1 {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}

/* List styling */
.guidelines-list {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  text-align: left; /* Align text to left for lists */
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align list */
}

.guidelines-list li {
  margin-bottom: 0.5rem;
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .storage-shipping-title {
    font-size: 2.5rem; /* Larger font size for the title */
  }

  .section-title {
    font-size: 1.75rem; /* Larger font size for section titles */
  }

  .section-description {
    font-size: 1.125rem; /* Larger font size for descriptions */
  }
}

@media (min-width: 1024px) {
  .storage-shipping-container {
    padding: 2rem; /* Extra padding on larger screens */
  }

  .section-title {
    font-size: 2rem; /* Larger font size for section titles */
  }

  .section-description {
    font-size: 1.25rem; /* Larger font size for descriptions */
  }
}
/* Container styling */
.return-payment-container {
  padding: 1rem;
  text-align: center; /* Center-align text */
}

/* Title styling */
.return-payment-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Section title styling */
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Description styling */
.section-description {
  font-size: 1rem;
  color: #4a5568; /* Gray color */
  margin-bottom: 1.5rem;
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align description */
}

/* Image styling */
.payment-cycle-img1 {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}

/* List styling */
.process-list, .timelines-list {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  text-align: left; /* Align text to left for lists */
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align list */
}

.process-list li, .timelines-list li {
  margin-bottom: 0.5rem;
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .return-payment-title {
    font-size: 2.5rem; /* Larger font size for the title */
  }

  .section-title {
    font-size: 1.75rem; /* Larger font size for section titles */
  }

  .section-description {
    font-size: 1.125rem; /* Larger font size for descriptions */
  }
}

@media (min-width: 1024px) {
  .return-payment-container {
    padding: 2rem; /* Extra padding on larger screens */
  }

  .section-title {
    font-size: 2rem; /* Larger font size for section titles */
  }

  .section-description {
    font-size: 1.25rem; /* Larger font size for descriptions */
  }
}
/* Container styling */
.return-payment-container {
  padding: 1rem;
  text-align: center; /* Center-align text */
}

/* Title styling */
.return-payment-title {
  font-size: 2rem;
  font-weight: bold;
  margin-bottom: 1rem;
}

/* Section title styling */
.section-title {
  font-size: 1.5rem;
  font-weight: bold;
  margin: 1rem 0;
}

/* Description styling */
.section-description {
  font-size: 1rem;
  color: #4a5568; /* Gray color */
  margin-bottom: 1.5rem;
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align description */
}

/* Image styling */
.payment-cycle-img1 {
  max-width: 100%;
  height: auto;
  margin: 1rem 0;
}

/* List styling */
.process-list, .timelines-list {
  list-style-type: disc;
  margin: 0;
  padding: 0;
  text-align: left; /* Align text to left for lists */
  max-width: 800px; /* Limit width for readability */
  margin-left: auto;
  margin-right: auto; /* Center-align list */
}

.process-list li, .timelines-list li {
  margin-bottom: 0.5rem;
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .return-payment-title {
    font-size: 2.5rem; /* Larger font size for the title */
  }

  .section-title {
    font-size: 1.75rem; /* Larger font size for section titles */
  }

  .section-description {
    font-size: 1.125rem; /* Larger font size for descriptions */
  }
}

@media (min-width: 1024px) {
  .return-payment-container {
    padding: 2rem; /* Extra padding on larger screens */
  }

  .section-title {
    font-size: 2rem; /* Larger font size for section titles */
  }

  .section-description {
    font-size: 1.25rem; /* Larger font size for descriptions */
  }
}

/* Container styling */
.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align benefit cards */
  padding: 1rem;
  gap: 1rem; /* Space between cards */
}

/* Card styling */
.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f9f9f9; /* Light background for cards */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 1rem;
  max-width: 250px; /* Limit width of cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Image styling */
.benefit-card img {
  width: 80px; /* Fixed width for images */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0.5rem; /* Space between image and text */
}

/* Title styling */
.benefit-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Description styling */
.benefit-card p {
  font-size: 0.875rem; /* Slightly smaller font size */
  color: #4a5568; /* Gray color */
  margin: 0;
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .benefit-card {
    max-width: 300px; /* Larger cards on bigger screens */
  }

  .benefit-title {
    font-size: 1.5rem; /* Larger title font size */
  }

  .benefit-card p {
    font-size: 1rem; /* Larger font size for description */
  }
}

@media (min-width: 1024px) {
  .benefits-container {
    padding: 2rem; /* Extra padding on larger screens */
    gap: 2rem; /* Increase space between cards */
  }

  .benefit-card {
    max-width: 350px; /* Larger cards on even bigger screens */
  }

  .benefit-title {
    font-size: 1.75rem; /* Larger title font size */
  }

  .benefit-card p {
    font-size: 1.125rem; /* Larger font size for description */
  }
}
/* Apply box-sizing for all elements */
*, *::before, *::after {
  box-sizing: border-box;
}

/* Container styling */
.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align benefit cards */
  padding: 1rem;
  gap: 1rem; /* Space between cards */
}

/* Card styling */
.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f9f9f9; /* Light background for cards */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 1rem;
  max-width: 250px; /* Limit width of cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth transition */
}

/* Image styling */
.benefit-card img {
  width: 80px; /* Fixed width for images */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0.5rem; /* Space between image and text */
}

/* Title styling */
.benefit-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Description styling */
.benefit-card p {
  font-size: 0.875rem; /* Slightly smaller font size */
  color: #333; /* Darker text for better readability */
  margin: 0;
}

/* Hover effect */
.benefit-card:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

/* Focus effect */
.benefit-card:focus {
  outline: 2px solid #4a5568; /* Visible outline for keyboard navigation */
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .benefit-card {
    max-width: 300px; /* Larger cards on bigger screens */
  }

  .benefit-title {
    font-size: 1.5rem; /* Larger title font size */
  }

  .benefit-card p {
    font-size: 1rem; /* Larger font size for description */
  }
}

@media (min-width: 1024px) {
  .benefits-container {
    padding: 2rem; /* Extra padding on larger screens */
    gap: 2rem; /* Increase space between cards */
  }

  .benefit-card {
    max-width: 350px; /* Larger cards on even bigger screens */
  }

  .benefit-title {
    font-size: 1.75rem; /* Larger title font size */
  }

  .benefit-card p {
    font-size: 1.125rem; /* Larger font size for description */
  }
}

/* Additional styling for very small screens */
@media (max-width: 480px) {
  .benefit-card {
    padding: 0.75rem; /* Reduced padding for smaller screens */
  }

  .benefit-title {
    font-size: 1.125rem; /* Smaller font size for better fit */
  }

  .benefit-card p {
    font-size: 0.75rem; /* Smaller font size for descriptions */
  }
}

/* Advanced feature support (e.g., CSS Grid) */
@supports (display: grid) {
  .benefits-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
}
@supports (display: grid) {
  .benefits-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 1rem;
  }
}
@media (max-width: 480px) {
  .benefit-card {
    padding: 0.75rem; /* Reduced padding for smaller screens */
  }

  .benefit-title {
    font-size: 1.125rem; /* Smaller font size for better fit */
  }

  .benefit-card p {
    font-size: 0.75rem; /* Smaller font size for descriptions */
  }
}
.benefit-card:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  transform: scale(1.05); /* Slight zoom effect */
  transition: all 0.3s ease; /* Smooth transition */
}

.benefit-card:focus {
  outline: 2px solid #4a5568; /* Visible outline for keyboard navigation */
}

/* Ensure good color contrast */
.benefit-card p {
  color: #333; /* Darker text for better readability */
}

.benefit-card img {
  alt: "Benefit Icon"; /* Add descriptive alt text for accessibility */
}
*, *::before, *::after {
  box-sizing: border-box;
}
/* Container styling */
.benefits-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center; /* Center-align benefit cards */
  padding: 1rem;
  gap: 1rem; /* Space between cards */
}

/* Card styling */
.benefit-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  background-color: #f9f9f9; /* Light background for cards */
  border: 1px solid #ddd; /* Light border */
  border-radius: 8px; /* Rounded corners */
  padding: 1rem;
  max-width: 250px; /* Limit width of cards */
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  transition: all 0.3s ease; /* Smooth transition */
}

/* Image styling */
.benefit-img {
  width: 80px; /* Fixed width for images */
  height: auto; /* Maintain aspect ratio */
  margin-bottom: 0.5rem; /* Space between image and text */
}

/* Title styling */
.benefit-title {
  font-size: 1.25rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}

/* Description styling */
.benefit-card p {
  font-size: 0.875rem; /* Slightly smaller font size */
  color: #333; /* Darker text for better readability */
  margin: 0;
}

/* Hover effect */
.benefit-card:hover {
  background-color: #e0e0e0; /* Slightly darker background on hover */
  transform: scale(1.05); /* Slight zoom effect */
}

/* Focus effect */
.benefit-card:focus {
  outline: 2px solid #4a5568; /* Visible outline for keyboard navigation */
}

/* Responsive styling for larger screens */
@media (min-width: 768px) {
  .benefit-card {
    max-width: 300px; /* Larger cards on bigger screens */
  }

  .benefit-title {
    font-size: 1.5rem; /* Larger title font size */
  }

  .benefit-card p {
    font-size: 1rem; /* Larger font size for description */
  }
}

@media (min-width: 1024px) {
  .benefits-container {
    padding: 2rem; /* Extra padding on larger screens */
    gap: 2rem; /* Increase space between cards */
  }

  .benefit-card {
    max-width: 350px; /* Larger cards on even bigger screens */
  }

  .benefit-title {
    font-size: 1.75rem; /* Larger title font size */
  }

  .benefit-card p {
    font-size: 1.125rem; /* Larger font size for description */
  }
}

/* Additional styling for very small screens */
@media (max-width: 480px) {
  .benefit-card {
    padding: 0.75rem; /* Reduced padding for smaller screens */
  }

  .benefit-title {
    font-size: 1.125rem; /* Smaller font size for better fit */
  }

  .benefit-card p {
    font-size: 0.75rem; /* Smaller font size for descriptions */
  }
}



/* Responsive Design */
@media (max-width: 768px) {
  .menu-button {
    display: inline-flex;
  }

  .nav-links {
    display: none;
  }

  .mobile-menu {
    display: block;
  }
}


/* Responsive Design */
@media (max-width: 768px) {
  .hidden {
    display: block;
  }

  .md\:hidden {
    display: none;
  }

  .md\:flex {
    display: none;
  }

  .md\:ml-6 {
    margin-left: 0;
  }

  .md\:space-x-8 {
    margin-right: 0;
  }

  .absolute {
    position: absolute;
  }

  .block {
    display: block;
  }

  .p-2 {
    padding: 0.5rem; /* 8px */
  }

  .mt-3 {
    margin-top: 0.75rem; /* 12px */
  }
}
/* Mobile View Styles for Steps Container */
@media (max-width: 768px) {
  .steps-container {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* 16px */
    padding: 1rem; /* Add padding to avoid content touching edges */
    overflow-x: hidden; /* Prevent horizontal overflow */
  }

  .step-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem; /* Adjust padding for mobile */
    border-radius: 0.5rem; /* 8px */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%; /* Ensure full-width on mobile */
    box-sizing: border-box; /* Include padding in width calculation */
    max-width: 300px; /* Restrict width to avoid overflow */
    margin: 0 auto; /* Center the card */
  }

  .step-circle {
    width: 2.5rem; /* 40px */
    height: 2.5rem; /* 40px */
    font-size: 1rem; /* 16px */
  }

  .step-title {
    font-size: 1.25rem; /* 20px */
    margin-top: 0.5rem; /* 8px */
  }

  ul {
    padding-left: 1.5rem; /* Adjust list padding */
  }

  ul li {
    font-size: 1rem; /* 16px */
    margin-bottom: 0.5rem; /* Space between list items */
    word-wrap: break-word; /* Handle long words */
  }
}
/* Mobile View Styles for Benefits Container */
@media (max-width: 768px) {
  .benefits-container {
    display: flex;
    flex-direction: column;
    gap: 1rem; /* 16px */
    padding: 1rem; /* Add padding to avoid content touching edges */
  }

  .benefit-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem; /* Adjust padding for mobile */
    border-radius: 0.5rem; /* 8px */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    text-align: center;
    width: 100%; /* Ensure full-width on mobile */
    box-sizing: border-box; /* Include padding in width calculation */
    max-width: 300px; /* Restrict width to avoid overflow */
    margin: 0 auto; /* Center the card */
  }

  .benefit-image {
    width: 3rem; /* 48px */
    height: 3rem; /* 48px */
    margin-bottom: 0.5rem; /* Space below image */
  }

  .benefit-title {
    font-size: 1rem; /* 16px */
    margin-top: 0.5rem; /* 8px */
  }

  .benefit-card p {
    font-size: 0.875rem; /* 14px */
    word-wrap: break-word; /* Handle long words */
  }
}
/* Tailwind CSS classes */
.bg-blue-900 {
  background-color: #1e3a8a;
}

.text-gray-100 {
  color: #f3f4f6;
}

.text-gray-600 {
  color: #4b5563;
}

.hover\:text-gray-600:hover {
  color: #4b5563;
}

.bg-white {
  background-color: #ffffff;
}

.text-blue-900 {
  color: #1e3a8a;
}

.hover\:bg-yellow-500:hover {
  background-color: #facc15;
}

.focus\:outline-none:focus {
  outline: none;
}

.rounded-md {
  border-radius: 0.375rem;
}

.shadow-lg {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px rgba(0, 0, 0, 0.1);
}

.w-52 {
  width: 13rem;
}

.origin-top-right {
  transform-origin: top right;
}

.divide-y {
  border-top-width: 1px;
}

.divide-gray-100 {
  --divide-opacity: 1;
  border-color: rgba(243, 244, 246, var(--divide-opacity));
}

.text-base {
  font-size: 1rem;
}

.font-medium {
  font-weight: 500;
}

.space-x-8 > :not([hidden]) ~ :not([hidden]) {
  margin-left: 2rem;
}

.space-y-1 > :not([hidden]) ~ :not([hidden]) {
  margin-top: 0.25rem;
}

.md\:hidden {
  display: none;
}

@media (min-width: 768px) {
  .md\:hidden {
    display: block;
  }
}