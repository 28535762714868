.shippingcont{
     display: flex;
    flex-direction: column;
    align-items: center;
    font-family: Arial, sans-serif;
    width: 50%;
    max-width: 1200px;
    background-color: white;
    border-radius: 10px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.383);
    margin: 10px;
    padding: 20px;
}

/* .shippingcont .previous {
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.383);
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80%;
    padding: 10px 20px;
    margin: 10px;
    gap: 10px;
} */
.shippingcont .radio {
    display: flex;
    text-align: left;
    width: 100%;
    margin-bottom: 20px;
    margin-left: 10px;
    padding-left: 10%;
    gap: 10px;
}

@media screen and (max-width: 800px) {
    .shippingcont {
        width: 95%;
    }
    .shippingcont .previous {
        width: 95%;
    }
    .shippingcont .selectdate {
        width: 95%;
    }
    .shippingcont .shippingadd {
        width: 95%;
    }
}