/* General Container Styles */
.useraddress {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    min-height: 100vh;
    padding: 20px;
    background-color: #f5f5f5;
}

/* Main Header Styles */
.mainhead1 {
    font-size: 2rem;
    margin-bottom: 20px;
    text-align: center;
    color: #333;
    font-weight: bold;
}

/* Address List Container */
.addressin {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(250px, 1fr));
    gap: 20px;
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
}

/* Individual Address Card Styles */
.address {
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    position: relative;
}

.address span {
    display: block;
    margin-bottom: 5px;
    font-size: 0.95rem;
    color: #555;
}

.editbtn, .delbtn {
    position: absolute;
    top: 10px;
    cursor: pointer;
}

.editbtn {
    right: 40px;
}

.delbtn {
    right: 10px;
}

/* Add New Button */
.addnewbtn {
    background-color: #007BFF;
    padding: 12px 20px;
    border-radius: 50%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
    color: white;
    cursor: pointer;
    margin: 20px auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    transition: background-color 0.3s ease;
}

.addnewbtn:hover {
    background-color: #0056b3;
}

/* Form Container */
.addnew {
    background-color: #fff;
    padding: 30px;
    border-radius: 10px;
    box-shadow: 0 6px 20px rgba(0, 0, 0, 0.15);
    max-width: 500px;
    width: 100%;
    margin: 20px auto;
}

.form-group {
    margin-bottom: 15px;
}

.form-group label {
    display: block;
    font-size: 0.9rem;
    color: #333;
    margin-bottom: 5px;
}

.form-group input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
    border: 1px solid #ccc;
    transition: border-color 0.3s ease;
}

.form-group input:focus {
    border-color: #007BFF;
    outline: none;
}

/* Save Button Styles */
.mainbutton1 {
    width: 100%;
    background-color: #28a745;
    padding: 12px;
    border: none;
    color: white;
    font-size: 1rem;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.mainbutton1:hover {
    background-color: #218838;
}

.mainbutton1:focus {
    outline: none;
}

/* Media Queries for Smaller Devices */
@media (max-width: 768px) {
    .addressin {
        grid-template-columns: 1fr;
    }

    .addnew {
        width: 90%;
    }
}

