.footer {
    background-color: #1e3a8a; /* Updated background color */
    color: #fff;
    padding: 40px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: flex-start;
}

/* Flexbox layout for desktop */
.footer-item {
    flex: 1 1 220px; /* Adjust the width of footer items */
    margin: 10px; /* Adjust margin between items */
}

.footer-logo {
    height: 80px;
    width: 80px;
    margin-bottom: 10px; /* Add margin for spacing */
}

/* Footer headings */
.footer-heading {
    font-size: 20px; /* Adjust heading font size */
    margin-bottom: 15px;
    font-weight: 600;
}

/* Footer lists */
.footer-list {
    list-style: none;
    padding: 0;
}

.footer-list-item {
    margin-bottom: 10px;
    font-size: 16px;
}

/* Footer links */
.footer-list-item a {
    color: #fff;
    text-decoration: none;
}

.footer-list-item a:hover {
    text-decoration: underline;
}

/* Responsive for tablets and smaller screens */
@media screen and (max-width: 1024px) {
    .footer {
        justify-content: center;
        text-align: center;
    }

    .footer-item {
        flex: 1 1 150px;
        margin-bottom: 20px;
    }

    .footer-logo {
        height: 60px;
        width: 60px;
    }

    .footer-heading {
        font-size: 18px;
    }
}

/* Responsive for mobile phones */
@media screen and (max-width: 768px) {
    .footer {
        flex-direction: column;
        align-items: center;
        text-align: center;
        padding: 20px;
    }

    .footer-item {
        flex: 1 1 auto;
        margin-bottom: 20px;
    }

    .footer-logo {
        height: 50px;
        width: 50px;
    }

    .footer-heading {
        font-size: 16px;
    }

    .footer-list-item {
        font-size: 14px;
    }
}
