.yourorders {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    /* height: 100vh; */
    padding: 20px;
    position: relative;
    /* overflow: hidden; */
}


.yourorderstable {
    border: 1px solid #ccc;
    border-collapse: collapse;
    margin: 0;
    padding: 0;
    width: 100%;
    table-layout: fixed;
}


.yourorderstable  thead tr {
    background-color: var(--col1);
    color: #fff;
    border: 1px solid #ddd;
    padding: .35em;
}
.yourorderstable tr {
    background-color: #ffffff;
    border: 1px solid #ddd;
    padding: .35em;
}


.yourorderstable  th,
.yourorderstable  td {
    text-align: center;
}

.yourorderstable tr th {
    font-size: .85em;
    letter-spacing: .1em;
    text-transform: uppercase;
    padding: 10px 0px;
}


.yourorderstable tr td {
    padding: 5px 20px;
    border: 1px solid rgb(207, 207, 207);
    border-radius: 5px;
    color: grey;
}


.yourorderstable tr td button {
    padding: 5px 20px;
    margin: 0px;
    font-size: 16px;
    
}

.yourorderstable tr td .greendot {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    
}

.yourorderstable tr td div{
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.yourorderstable tr td .yellowdot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: orange;
}

.yourorderstable tr td .reddot {
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: rgb(255, 81, 81);
}


@media screen and (max-width: 1200px) {
    
    .yourorderstable thead {
        border: none;
        clip: rect(0 0 0 0);
        height: 1px;
        margin: -1px;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 1px;
    }
    .yourorderstable tr {
        display: block;
        margin-bottom: .625em;
        border: none;
    }

    .yourorderstable tr td {
        border: none;
        border-radius: 0px;
        display: block;
        font-size: .8em;
        text-align: right;
        display: flex;
        justify-content: space-between;
    }

    .yourorderstable td::before{
        content:attr(data-label);
    }
}

/* Global Styling */
body {
    font-family: 'Roboto', sans-serif;
    background-color: #f1f3f6;
    margin: 0;
    padding: 0;
}
/* Container for Orders Section */
.yourorders {
    width: 90%;
    margin: 30px auto;
    padding: 20px;
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Main Header */
.mainhead1 {
    text-align: left;
    color: #2874f0;
    font-size: 26px;
    font-weight: bold;
    margin-bottom: 20px;
}

/* Orders Container */
.orders-container {
    display: flex;
    flex-wrap: wrap;
    gap: 20px; /* Spacing between cards */
    justify-content: center; /* Center the cards */
}

/* Order Card Styling */
.order-card {
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 10px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
    flex: 1 1 calc(33% - 20px); /* Adjust width for up to 3 cards per row */
    max-width: 350px; /* Maximum width constraint */
    padding: 20px;
    transition: transform 0.3s ease;
    min-width: 250px; /* Minimum width to prevent shrinkage */
}

.order-card:hover {
    transform: translateY(-5px); /* Slight upward movement on hover */
}

.order-card h3 {
    margin-top: 0;
    color: #333;
}

/* Order Details Styling */
.order-details {
    display: flex;
    flex-direction: column;
    gap: 10px;
    margin-bottom: 15px;
}

/* Order Detail Items */
.order-item {
    display: flex;
    justify-content: space-between;
    font-size: 14px;
    color: #555;
}

/* Total Amount Styling */
.order-total {
    font-size: 16px;
    font-weight: bold;
    color: #000;
}

/* Button Container */
.button-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
}

/* Action Button Styling */
.action-btn {
    flex: 1;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    color: white;
    transition: background-color 0.3s ease;
}

/* Specific Button Styles */
.invoice-btn {
    background-color: #2874f0;
}

.invoice-btn:hover {
    background-color: #0d6efd;
}

.return-btn {
    background-color: #ff8c00; /* Orange color */
}

.return-btn:hover {
    background-color: #ff5c00;
}

.cancel-btn {
    background-color: #ff5252; /* Red color */
}

.cancel-btn:hover {
    background-color: #ff0000;
}

/* Responsive Adjustments */
@media screen and (max-width: 768px) {
    .order-card {
        width: calc(50% - 20px); /* Adjust for 2 cards per row */
    }
}

@media screen and (max-width: 480px) {
    .order-card {
        width: 100%; /* Full-width cards for mobile screens */
    }
}
