/* Header Styles */
.main-header {
  background-color: #ffffff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  padding: 10px 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.col-nav {
  align-items: center;
  margin-right: 2rem;
  width: 100%;
}

.btn-mobile {
  display: none;
}

.nav {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  align-items: center;
}

.nav-item {
  margin-left: 15px;
}

.dropdown-toggle {
  display: flex;
  align-items: center;
  margin-left: -2em;
}

.img-xs {
  width: 40px;
  height: 40px;
}

.dropdown-menu {
  min-width: 200px;
  margin-left: -1em;
  margin-top: 2px;
}

@media (max-width: 768px) {
  .btn-mobile {
    display: block;
  }

  .main-header {
    padding: 10px;
    flex-direction: column;
    align-items: flex-start;
  }

  .col-nav {
    width: 100%;
    justify-content: space-between;
  }

  .nav {
    width: 100%;
    justify-content: space-between;
  }

  .nav-item {
    margin-left: 0;
  }

  .dropdown-menu {
    width: 100%;
  }
}
/* Global styles */
* {
box-sizing: border-box;
margin: 0;
padding: 0;
}

body {
font-family: Arial, sans-serif;
}

/* Header styles */
.main-header {
display: flex;
justify-content: space-between;
align-items: center;
padding: 1rem;
background-color: #fff;
box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.btn-mobile {
display: none; 
}

.nav {
list-style-type: none;
display: flex;
gap: 1rem;
}

.dropdown-menu {
min-width: 150px;
}

/* Sidebar styles */
.navbar-aside {
position: fixed;
top: 0;
left: 0;
width: 250px;
height: 100vh;
background-color: #fff;
box-shadow: 2px 0 4px rgba(0, 0, 0, 0.1);
transition: transform 0.3s ease;
transform: translateX(-100%); /* Hide off-screen by default */
overflow-y: auto; /* Ensure scrolling if content is too long */
}

.navbar-aside.show {
transform: translateX(0); /* Show sidebar when class 'show' is added */
}

.brand-wrap {
display: flex;
align-items: center;
padding: 1rem;
border-bottom: 1px solid #ddd;
}

.brand-link {
display: flex;
align-items: center;
}

.btn-aside-minimize {
position: absolute;
top: 1rem;
right: 1rem;
}

.menu-aside {
list-style-type: none;
padding: 1rem;
}

.menu-item {
margin-bottom: 1rem;
}

.menu-link {
display: flex;
align-items: center;
text-decoration: none;
color: #333;
padding: 0.5rem;
border-radius: 0.25rem;
}

.menu-link.active, .menu-link:hover {
background-color: #f0f0f0;
}

.icon {
margin-right: 0.5rem;
}

/* Mobile view adjustments */
@media (max-width: 768px) {
.btn-mobile {
  display: block;
}

.nav {
  display: none; /* Hide nav items on mobile */
}

.navbar-aside {
  transform: translateX(-100%); /* Hide off-screen by default */
}

.navbar-aside.show {
  transform: translateX(0); /* Show sidebar on mobile */
}
}
/* Mobile Header */
.mobile-header {
display: none;
}

/* Desktop Header */
.desktop-header {
display: flex;
}

/* Mobile Sidebar */
.navbar-aside.mobile-sidebar {
position: fixed;
top: 0;
left: -250px; /* Initially hidden off-screen */
width: 250px;
height: 100%;
background-color: #343a40; /* Dark background color */
transition: left 0.3s ease; /* Smooth transition */
overflow-y: auto; /* Scrollable content */
z-index: 1000; /* Ensure it appears above other content */
}

.navbar-aside.mobile-sidebar.show {
left: 0; /* Show sidebar */
}

/* Show mobile header on small screens */
@media (max-width: 767px) {
.mobile-header {
  display: block; /* Show mobile header */
}

.desktop-header {
  display: none; /* Hide desktop header */
}
}

/* Show desktop header on larger screens */
@media (min-width: 768px) {
.mobile-header {
  display: none; /* Hide mobile header */
}

.desktop-header {
  display: flex; /* Show desktop header */
}

.navbar-aside.mobile-sidebar {
  display: none; /* Hide mobile sidebar on larger screens */
}
}
