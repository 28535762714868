.productpage .pc1 {
    display: flex;
    justify-content: flex-start; /* Align items to start */
    align-items: flex-start; /* Align vertically to the top */
    position: relative;
  }
  
  .productpage .pc1 .c11 {
    display: flex;
    flex-direction: column; /* Stack small images vertically */
    width: 20%; /* Set width for small images container */
  }
  
  .productpage .pc1 .c11 .imgset {
    display: flex;
    flex-direction: column; /* Ensure the images are in a vertical column */
    gap: 10px; /* Add spacing between the small images */
  }
  
  .productpage .pc1 .c11 .imgsmall img {
    width: 100%; /* Full width of the container */
    aspect-ratio: 1/1;
    cursor: pointer;
    border-radius: 5px;
    transition: transform 0.3s ease;
  }
  
  .productpage .pc1 .c11 .imgsmall img:hover {
    transform: scale(1.1); /* Slight zoom on hover */
  }
  
  .productpage .pc1 .c11 .active {
    border: 5px solid #5ac6f8;
  }
  
  .productpage .pc1 .c11 .imgbig {
      width: 70%;
      display: flex;
      flex-direction: column;
      aspect-ratio: 1/1;
  }
  
  .productpage .pc1 .c12 {
      display: flex;
      width: 40%;
      flex-direction: column;
  }
  
  
  .productpage .pc1 .c12 .c121 {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
  }
  
  .productpage .pc1 .c12 .price {
      font-size: 2rem;
      font-weight: 600;
      color: var(--col1);
      display: flex;
      align-items: center;
  }
  
  
  .productpage .pc1 .c12 .price span {
      font-size: 20px;
      font-weight: 400;
      color: gray;
      text-decoration: line-through;
      margin-left: 10px;
  }
  
  .price-container {
    display: flex;
    align-items: center; /* Aligns price and discount percentage on the same line */
  }
  
  .price {
    font-size: 1.5rem;
    color: black;
    margin-right: 10px; /* Add space between price and discount percentage */
  }
  
  .price .actual-price {
    margin-left: 8px;
    text-decoration: line-through; /* Strikethrough only on the actual price */
    color: red;
  }
  
  .discount-box {
    padding: 5px 10px;
    background-color: green;
    color: white;
    font-weight: bold;
    border-radius: 5px;
  }
  
  
  .productpage .btncont {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      gap: 10px;
  }
  
  .productpage .btncont button {
      width: 200px;
      height: 50px;
      border: none;
      background-color:#f0c014;
      color: white;
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
  }
  
  
  .productpage .btncont button:hover {
      background-color: rgb(228, 142, 42);
  }
  
  .productpage .pc2 {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      width: 100%;
      /* background-color: aqua; */
  }
  
  .productpage .pc2 .tabs {
      display: flex;
      margin: 20px 0px;
      gap: 20px;
      align-items: center;
      justify-content: center;
  }
  
  .productpage .pc2 .tabs .active {
      border: none;
      font-size: 1.5rem;
      font-weight: 600;
      color: var(--col1);
      cursor: pointer;
      border-bottom: 2px solid var(--col1);
      margin: 0px 10px;
      background-color: white;
  }
  
  .productpage .pc2 .tabs .inactive {
      border: none;
      font-size: 1.2rem;
      font-weight: 600;
      color: gray;
      cursor: pointer;
      border-bottom: 2px solid transparent;
      margin: 0px 10px;
      background-color: white;
  }
  
  
  .productpage .desc{
      width: 70%;
      max-width: 900px;
      align-self: center;
  }
  
  
  @media only screen and (max-width: 768px) {
      .productpage .pc1 {
          flex-direction: column;
          align-items: center;
      }}
      .productpage .pc1 .c11 {
          width: 80%;
          /* background-color: aqua; */
          align-items: center;
          flex-direction: column-reverse;
      }
      .productpage .pc1 .c11 .imgset {
          max-width: 300px;
          width: 100%;
          margin: 30px 5px;
          /* background-color: aqua; */
          flex-direction: row;
      }
      .productpage .pc1 .c12 {
          width: 80%;
    
      
     
  
      .productpage .desc {
          width: 90%;
          max-width: 900px;
          align-self: center;
          text-align: center;
          font-size: 13px;
          font-weight: 300;
          color: var(--col2);
      }
  
    
      .productpage .pc1 .c11 .imgbig {
          width: 70%;
          /* background-color: aqua; */
          display: flex;
          flex-direction: column;
          aspect-ratio: 1/1;
      }
  }
  
  /* Add this CSS in your stylesheet */
  .image-container {
      position: relative;
    }
    
    .active-img {
      transition: transform 0.3s ease;
    }
    
    .active-img.zoomed {
      transform: scale(1.5); /* Adjust zoom level as needed */
    }
    
    .productpage .pc1 .imgbig {
        position: relative;
        width: 70%;
        display: flex;
        flex-direction: column;
        aspect-ratio: 1/1;
    }
    
    .productpage .pc1 .imgbig img {
        width: 100%;
        height: auto;
        object-fit: cover;
    }
    
    .productpage .pc1 .imgbig .share-button {
        position: absolute;
        top: 10px; /* Adjust position as needed */
        right: 10px; /* Adjust position as needed */
        font-size: 20px;
        color: rgb(24, 181, 42);
        cursor: pointer;
        width: 40px;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(255, 255, 255, 0.8); /* Slight opacity for better blending */
        border-radius: 50%; /* Circular button */
        border: none;
        outline: none;
        transition: background-color 0.3s ease;
    }
    
    .productpage .pc1 .imgbig .share-button:hover {
        background-color: rgba(236, 168, 90, 0.8); /* Hover effect */
    }
    
    /* Zoom Effect */
    .image-container {
        position: relative;
    }
    
    .image-container .active-img {
        transition: transform 0.3s ease;
    }
    
    .image-container .active-img.zoomed {
        transform: scale(1.5); /* Adjust zoom level as needed */
    }
    
    /* Zoom-in effect */
.zoom-container {
    position: relative;
  }
  
  .zoom-image {
    width: 100%;
    height: 100%;
    overflow: hidden;
  }
  
  .zoom-image:hover img {
    transform: scale(1.5);
    cursor: zoom-in;
  }
  
  .active-image {
    width: 100%;
    height: 100%;
    transition: transform 0.3s ease;
  }
  
  .share-button {
    position: absolute;
    top: 10px;
    right: 10px;
    background-color: white;
    border: none;
    cursor: pointer;
    padding: 5px;
    font-size: 16px;
    border-radius: 50%;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  }
  
  .share-button i {
    color: #666;
  }
  
  .share-button:hover i {
    color: #333;
  }
  
  .imgset {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  }
  
  