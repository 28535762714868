.userprofile {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    background-color: #e0f7fa;
    /* Light cool blue color */
  }

  
  
  .userprofile-content {
    display: flex;
    flex-direction: row;
    width: 80%;
    margin: 20px auto;
    justify-content: space-between;
    gap: 20px;
    box-sizing: border-box;
  }
  
  .sidebar {
    flex: 1;
    min-width: 250px;
    border: 1px solid #141414; /* Cool greyish color */
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .main-content {
    flex: 3;
    border: 1px solid #b2dfdb; /* Cool greyish color */
    border-radius: 8px;
    background-color: #ffffff; /* White background */
    padding: 20px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  @media (max-width: 1000px) {
    .userprofile-content {
      flex-direction: column;
      gap: 10px;
      width: 90%;
    }
  
    .sidebar, .main-content {
      min-width: 100%;
    }
  }
  
  @media (max-width: 500px) {
    .userprofile-content {
      padding: 10px;
      width: 100%;
    }
  
    .sidebar, .main-content {
      min-width: 100%;
    }
  }
  