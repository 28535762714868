/* Container for sidebar and content */
.container {
  display: flex;
  font-family: Arial, sans-serif;
}

/* Sidebar styling */
.sidebar {
  width: 250px;
  background-color: white;
  padding-top: 20px;
  position: fixed;
  height: 100%;
}

.sidebar a {
  display: block;
  color: black;
  padding: 10px 15px;
  text-decoration: none;
  position: relative;
  transition: all 0.3s ease;
}

/* On hover, add a border-left line */
.sidebar a:hover {
  background-color: white;
  border-left: 4px solid black;
  padding-left: 11px;
}

.sidebar a::before {
  content: '';
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  width: 0;
  background-color: black;
  transition: width 0.3s ease;
}

.sidebar a:hover::before {
  width: 4px;
}

/* Main content styling */
.content {
  margin-left: 250px;
  padding: 20px;
  background-color: white;
  height: 100vh;
  overflow-y: scroll;
}

/* Section styling */
.section {
  padding: 20px;
  margin-bottom: 50px;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

.section h2 {
  margin-top: 0;
  font-weight: bold;
  font-size: 18px;
}

.section h3 {
  font-weight: bold;
}

.section p {
  line-height: 1.6;
  font-size: 16px;
  margin: 15px 0;
  color: #333;
}

/* List styling */
.section ul {
  padding-left: 20px;
}

.section ul li {
  margin-bottom: 10px;
  font-size: 15px;
}

/* Smooth scrolling */
html {
  scroll-behavior: smooth;
}

/* Responsive Design */

/* For tablets and mobile devices */
@media screen and (max-width: 768px) {
  /* Make sidebar collapsible on mobile */
  .sidebar {
    width: 100%;
    height: auto;
    position: relative;
  }

  /* Stack the container items vertically */
  .container {
    flex-direction: column;
  }

  /* Adjust content area on mobile */
  .content {
    margin-left: 0;
    padding: 10px;
  }

  /* Adjust section padding and font sizes on mobile */
  .section {
    padding: 15px;
  }

  .section h2 {
    font-size: 16px;
  }

  .section p {
    font-size: 14px;
  }

  .section ul li {
    font-size: 14px;
  }
}

/* For smaller mobile screens */
@media screen and (max-width: 480px) {
  .section {
    padding: 10px;
  }

  .section h2 {
    font-size: 14px;
  }

  .section p {
    font-size: 12px;
  }

  .section ul li {
    font-size: 12px;
  }

  .sidebar a {
    padding: 8px 10px;
  }
}
